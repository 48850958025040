import * as React from 'react';
import {useEffect, useState} from 'react';
import {Button } from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";

import {createTheme, ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import {PersonAddAlt} from "@mui/icons-material";
import AppBar from "@mui/material/AppBar";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ListItems from '../../theme/components/listItems';
import axios from "axios";

import Copyright from "../../theme/components/Copyright";
import {Drawer} from "../../theme/components/StyledComponent";
import {IdeaEnum,   StatusEnum} from "../../theme/components/list_enum";
import useToken from "../../components/useToken";
import Login from "../../layouts/Login";
import LogoutButton from "../../components/Logout";

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();


export default function EditIdea() {
    const {id} = useParams();
    const [open, setOpen] = useState(true);
    const [disabled, setDisabled] = useState(false);
    const [selectedImage, setSelectedImage] = useState();
    const [titre, setTitre] = useState("");
    const [description, setDescription] = useState("");
    const [type, setType] = useState(0);
    const [status, setStatus] = useState(0);
    const [idea, setIdea] = useState([]);
    const { token,  setToken } = useToken();
    const toggleDrawer = () => {
        setOpen(!open);
    };
    const navigate = useNavigate();

    useEffect(() => {
        async function getIdea(id) {
            try {
                // use data destructuring to get data from the promise object
                const {data: response} = await axios.get(`https://admin.sogreen.tn/api/idea_crud/${id}`);
                setIdea(response);
                setTitre(response.titre);
                setDescription(response.description);
                setType(response.type);
                setStatus(response.status);
                return response;
            } catch (error) {
                console.log(error);
            }
        }

        getIdea(id)
    }, [id ]);

    const GoToHandler = (url) => {
        navigate(url);
    }
    const handleGoTo = (path) => (e) => {
        GoToHandler(path);
    };
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        if(name==='titre'){
            setTitre(value);
        }
        if(name==='description'){
            setDescription(value);
        }
        if(name==='type'){
            setType(value);
        }
        if(name==='status'){
            setStatus(value);
        }

    }
    const removeSelectedImage = () => {
        setSelectedImage();
        setDisabled(false);
    } ;
    const imageChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setDisabled(true);
            setSelectedImage(e.target.files[0]);
        }
    };

    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
    const handleSubmit = (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append("id", id);
        formData.append("action", 'Edit');
        formData.append("titre", titre);
        formData.append("description", description);
        formData.append("type", type);
        formData.append("status", status);
        formData.append("photo", selectedImage );

        axios.post(`https://admin.sogreen.tn/api/idea_crud/${id}/edit`, formData, config).then(function (response) {
            navigate('/ideas_and_conseil');
        });

    }

    let imageC = '';
    console.log(idea);
    if (idea.photos) {
        if (idea.photos.toLowerCase().includes("http://") || idea.photos.toLowerCase().includes("https://")) {
            imageC = <img src={idea.photos} width='150' alt=''/>

        } else {
            imageC = <img src={`https://demo.sogreen.tn${idea.photos}`} alt='' width='150'/>

        }
    }

    if(!token) {
        return <Login setToken={setToken} />
    }
    return (
        <ThemeProvider theme={defaultTheme}>
            <CssBaseline />
            <AppBar position="absolute" open={open}>
                <Toolbar  sx={{ pr: '24px', }} >
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleDrawer}
                        sx={{
                            marginRight: '36px',
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{ flexGrow: 1 }}
                    >
                        Dashboard
                        </Typography>
                        <LogoutButton />

                </Toolbar>
                <Box sx={{ display: 'flex' }}>
                    <Drawer variant="permanent" open={open}>
                        <Toolbar
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                                px: [1],
                            }}
                        >
                            <IconButton onClick={toggleDrawer}>
                                <ChevronLeftIcon />
                            </IconButton>
                        </Toolbar>
                        <Divider />
                        <List component="nav">
                            <ListItems/>
                        </List>
                    </Drawer>
                    <Box
                        component="main"
                        sx={{
                            backgroundColor: (theme) =>
                                theme.palette.mode === 'light'
                                    ? theme.palette.grey[100]
                                    : theme.palette.grey[900],
                            flexGrow: 1,
                            height: '100vh',
                            overflow: 'auto',
                        }}
                    >
                        <Toolbar />
                    <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
                        <Grid container spacing={3}>
                            {/* Chart */}
                            <Grid item xs={12} md={8} lg={9}>
                                <Paper style={{padding: '0 10px'}}
                                       sx={{
                                           p: 2,
                                           display: 'flex',
                                           flexDirection: 'column',
                                           height: 50,
                                       }}
                                ><h2 style={{margin: 4, padding: 0}}>Editer l'idée/conseil</h2>

                                </Paper>
                            </Grid>
                            {/* Recent Deposits */}
                            <Grid item xs={12} md={4} lg={3}>
                                <Paper style={{padding: '0 10px'}}
                                       sx={{
                                           p: 2,
                                           display: 'flex',
                                           flexDirection: 'column',
                                           height: 50,
                                       }}
                                >
                                    <Button variant="contained"
                                            size="medium"
                                            aria-label="Ajouter "
                                            aria-controls="primary-account-menu"
                                            color="success"
                                            onClick={handleGoTo('/ideas_and_conseil/create')}
                                    >
                                        <PersonAddAlt/>Ajouter
                                    </Button>
                                </Paper>
                            </Grid>
                            {/* Recent Orders */}
                            <Grid item xs={12}>
                                <Paper sx={{p: 1, display: 'flex', flexDirection: 'column'}}>

                                    <form onSubmit={handleSubmit}>
                                        <table cellSpacing="10" style={{width: '100%'}}>
                                            <tbody>
                                            <tr>
                                                <th>
                                                    <label>Titre: </label>
                                                </th>
                                                <td>
                                                    <input defaultValue={idea.titre} type="text" name="titre"
                                                           onChange={handleChange}/>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    <label>Description: </label>
                                                </th>
                                                <td>
                                                    <textarea style={{width: '100%'}} rows='4' name="description"
                                                              defaultValue={idea.description}
                                                              onChange={handleChange}
                                                    />

                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    <label>Type: </label>
                                                </th>
                                                <td >

                                                    <select name="type" id='type'
                                                            value={type}  onChange={(e) => setType(e.target.value)} >
                                                        {IdeaEnum.map((titre, index) => (
                                                            <option
                                                                key={index}
                                                                value={titre}
                                                            >{titre}</option>
                                                        ))}
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    <label>Etat: </label>
                                                </th>
                                                <td>
                                                    <select name="status" id='status'
                                                          onChange={(e) => setStatus(e.target.value)}
                                                            value={status}>
                                                        {StatusEnum.map((name, index) => (
                                                            <option key={index}
                                                                    value={name}
                                                            >{name}</option>
                                                        ))}
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>

                                                    <label>Ancienne Photo: </label>
                                                </th>
                                                <td   align="right" height={110}>
                                                    <div style={{marginLeft:15}}>
                                                        <div>{imageC}</div>

                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2} >

                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    <label>Photo: </label>
                                                </th>
                                                <td   align="right" height={160}>

                                                    <div style={styles.container}>
                                                        <input className="inputimgplante"
                                                               accept="image/*"
                                                               type="file"
                                                               name="photo"

                                                               onChange={imageChange}

                                                               disabled={disabled}
                                                        />

                                                        {selectedImage && (
                                                            <div style={{
                                                                position: 'relative',
                                                                marginTop: 0,
                                                            }}>
                                                                <div style={styles.preview}>
                                                                    <IconButton onClick={removeSelectedImage}
                                                                                style={styles.delete}>
                                                                        <CloseIcon/>
                                                                    </IconButton>
                                                                    <img
                                                                        src={URL.createObjectURL(selectedImage)}
                                                                        style={styles.image}
                                                                        alt="photos"
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}

                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="2" align="right">
                                                    <Button  variant="contained"  className='btnaction' type='submit'>Sauvegarder</Button>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </form>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Container>
                    <Copyright sx={{ pt: 4 }} />
                </Box>
            </Box>
        </AppBar>
</ThemeProvider>
    );
}


// Just some styles
const styles = {
    container: {
        flexDirection: "column",
        justifyContent: "left",
        alignItems: "left",
        maxWidth: 'auto' ,
        width: 'auto',
        paddingTop: 5,
        marginTop: 10,
        marginLeft: 20,
        background: '#f1f1f1',
        position: 'relative'

    },
    preview: {
        position: 'relative',
        maxWidth: 100,
        minHeight: 100,
        padding: 0,
        margin: 0,
        marginTop: 20,
        display: "flex",
        flexDirection: "column",
        borderRadius: 10,
    },
    image: {
        width: 100,
        height: 100,
        position: 'absolute',
        top: 0, right: 0
    },
    delete: {
        zIndex: 10,
        position: 'absolute',
        top: 0, right: 0,
        width: 25, height: 25,
        cursor: "pointer",
        padding: 0,
        background: "#EEEEEE",
        color: "red",
        border: "none",
    },
};
