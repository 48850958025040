export const planteCatNames = [
    '____________',
    'Annuelles et bisannuelles',
    'Arbres',
    'Arbres et arbustes fruitiers',
    'Arbustes',
    'Bambous',
    'Gazon',
    'Graines & Bulbes',
    'Plantes de haie',
    'Plantes potagères & aromatiques',
    'Rosiers',
    'Vivace herbacée',
    'Fleurs vivaces a feuilles persistantes',
    'Grimpant a feuilles persistantes',
];
export const planteCatNamesC = [

    {
        value: 0,
        label: '____________'
    },
    {
        value: 1,
        label: 'Annuelles et bisannuelles'
    },
    {
        value: 2,
        label: "Arbres"
    },
    {
        value: 3,
        label: "Arbres et arbustes fruitiers"
    },
    {
        value: 4,
        label: "Arbustes"
    },
    {
        value: 5,
        label: "Bambous"
    },
    {
        value: 6,
        label: "Gazon"
    },
    {
        value: 7,
        label: "Graines & Bulbes"
    },
    {
        value: 8,
        label: "Plantes de haie"
    },
    {
        value: 9,
        label: "Plantes potagères & aromatiques"
    },
    {
        value: 10,
        label: "Rosiers"
    },
    {
        value: 11,
        label: "Vivace herbacée"
    },
    {
        value: 12,
        label: "Fleurs vivaces a feuilles persistantes"
    },
    {
        value: 13,
        label: "Grimpant a feuilles persistantes"
    }
];
export const StatusEnum = [
    'Inactive',
    'Active'
];

export const IdeaEnum = [
    'Idée',
    'Conseil'
];
