import { useState } from 'react';

export default function useAdmin() {
    const getAdmin = () => {
        const adminString = localStorage.getItem('admin');
        const userAdmin = JSON.parse(adminString);
        return userAdmin?.admin
    };

    const [admin, setAdmin] = useState(getAdmin());

    const saveAdmin = userAdmin => {
        localStorage.setItem('admin', JSON.stringify(userAdmin));

        if(userAdmin){
            setAdmin(userAdmin.admin);
        }
    };

    return {
        setAdmin: saveAdmin,
        admin
    }
}
