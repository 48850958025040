import Dashboard from "./layouts/Dashboard";
import React  from 'react';
import Login from "./layouts/Login";
import useToken from './components/useToken';
import useAdmin from "./components/useAdmin";

function App() {
  const { token,  setToken } = useToken();
  const { admin,  setAdmin } = useAdmin();

  if(!token) {
    return <Login setToken={setToken} />
  }

  return (
        <Dashboard  admin={admin} />
  );
}
export default App;
