import * as React from 'react';
import {
    Button
} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";

import {createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import MenuIcon from '@mui/icons-material/Menu';
import {PersonAddAlt } from "@mui/icons-material";
import AppBar from "@mui/material/AppBar";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ListItems from '../../theme/components/listItems';
import {useEffect, useState} from "react";
import axios from "axios";

import Copyright from "../../theme/components/Copyright";
import {Drawer} from "../../theme/components/StyledComponent";
import useToken from "../../components/useToken";
import Login from "../../layouts/Login";
import LogoutButton from "../../components/Logout";

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function EditCategory() {
    const [open, setOpen] = React.useState(true);
    const { token,  setToken } = useToken();
    const toggleDrawer = () => {
        setOpen(!open);
    };
    const navigate = useNavigate();
    const [inputs, setInputs] = useState([]);

    const {id} = useParams();

    useEffect(() => {
        getCat(id);
    }, [id]);

    function getCat(id) {
        axios.get(`https://admin.sogreen.tn/api/categories_crud/${id}`).then(function(response) {
         //   console.log(response.data);
            setInputs(response.data);
        });
    }

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}));
    }
    const handleSubmit = (event) => {
        event.preventDefault();

        axios.put(`https://admin.sogreen.tn/api/categories_crud/${id}/edit`, inputs).then(function(response){
          console.log(response.data);
            //navigate('/categoryplantes');
        });

    }

    const GoToHandler = (url) => {
        navigate(url);
    }
    const handleGoTo = (path) => (e) => {
        GoToHandler(path);
    };
    if(!token) {
        return <Login setToken={setToken} />
    }
    return (

        <ThemeProvider theme={defaultTheme}>
            <CssBaseline />
            <AppBar position="absolute" open={open}>
                <Toolbar  sx={{ pr: '24px', }} >
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleDrawer}
                        sx={{
                            marginRight: '36px',
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{ flexGrow: 1 }}
                    >
                        Dashboard
                        </Typography>
                        <LogoutButton />

                </Toolbar>
                <Box sx={{ display: 'flex' }}>
                    <Drawer variant="permanent" open={open}>
                        <Toolbar
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                                px: [1],
                            }}
                        >
                            <IconButton onClick={toggleDrawer}>
                                <ChevronLeftIcon />
                            </IconButton>
                        </Toolbar>
                        <Divider />
                        <List component="nav">
                            <ListItems/>
                        </List>
                    </Drawer>
                    <Box
                        component="main"
                        sx={{
                            backgroundColor: (theme) =>
                                theme.palette.mode === 'light'
                                    ? theme.palette.grey[100]
                                    : theme.palette.grey[900],
                            flexGrow: 1,
                            height: '100vh',
                            overflow: 'auto',
                        }}
                    >
                        <Toolbar />
                    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                        <Grid container spacing={3}>
                            {/* Chart */}
                            <Grid item xs={12} md={8} lg={9}>
                                <Paper style={{  padding:'0 10px'}}
                                       sx={{
                                           p: 2,
                                           display: 'flex',
                                           flexDirection: 'column',
                                           height: 50,
                                       }}
                                >  <h2 style={{margin:4, padding:0}}>Editer la categorie</h2>

                                </Paper>
                            </Grid>
                            {/* Recent Deposits */}
                            <Grid item xs={12} md={4} lg={3}>
                                <Paper style={{  padding:'0 10px'}}
                                       sx={{
                                           p: 2,
                                           display: 'flex',
                                           flexDirection: 'column',
                                           height: 50,
                                       }}
                                >
                                    <Button variant="contained"
                                            size="medium"
                                            aria-label="Ajouter une  categorie"
                                            aria-controls="primary-account-menu"
                                            color="success"
                                            onClick={handleGoTo('/category/create')}
                                    >
                                        <PersonAddAlt/>Ajouter
                                    </Button>
                                </Paper>
                            </Grid>
                            {/* Recent Orders */}
                            <Grid item xs={12}>
                                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>

                                    <form onSubmit={handleSubmit}>
                                        <table cellSpacing="10"  style={{width: '100%'}}>
                                            <tbody>
                                            <tr>
                                                <th>
                                                    <label>Nom: </label>
                                                </th>
                                                <td>
                                                    <input value={inputs.name} defaultValue={inputs.name} type="text" name="name" onChange={handleChange} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    <label>Description: </label>
                                                </th>
                                                <td>
                                                    <textarea style={{width: '100%'}} rows='4' name="description" onChange={handleChange} defaultValue={inputs.description} >{inputs.description}</textarea>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    <label>Etat: </label>
                                                </th>
                                                <td>
                                                    <select name="status" id='status' onChange={handleChange} defaultValue={inputs.status}>
                                                        <option value='0' selected={!(inputs.status)} >Inactive</option>
                                                        <option value='1' selected={(inputs.status)}>Active</option>
                                                    </select>


                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="2" align ="right">
                                                    <button>Sauvegarder</button>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </form>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Container>
                    <Copyright sx={{ pt: 4 }} />
                </Box>
            </Box>
        </AppBar>
</ThemeProvider>
    );
}

