import React  from 'react';
import { useNavigate} from "react-router-dom";
import useAdmin from "./useAdmin";
import useToken from "./useToken";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import LogoutIcon from '@mui/icons-material/Logout';
import Login from "../layouts/Login";

export default function LogoutButton() {

    const { admin,  setAdmin } = useAdmin();
    const { token,  setToken } = useToken();
    const navigate = useNavigate();

    const handleLogout = () => {
        logout();

    };
    const logout =  () => {
        try {
            localStorage.removeItem('token');
            localStorage.removeItem('admin');
            navigate('/');
        } catch (e) {
            console.log('error logout', e);
        }
    }

    if(!token) {
        return <Login setToken={setToken} />
    }
    return (
        <IconButton color="inherit"    onClick={handleLogout}>
            <Badge  color="secondary" >
                <LogoutIcon />   {admin.first_name}
            </Badge>
        </IconButton>
    );
}
