import * as React from 'react';
import {useState} from 'react';
import {useNavigate} from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import { Button } from "@mui/material";
import {createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import ListItems from '../../theme/components/listItems';
import Copyright from "../../theme/components/Copyright";
import {Drawer} from "../../theme/components/StyledComponent";
import {planteCatNames} from "../../theme/components/list_enum";
import useToken from "../../components/useToken";
import Login from "../../layouts/Login";
import LogoutButton from "../../components/Logout";


// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function CreatePlantLibrary() {
    const [open, setOpen] =  useState(true);
    const [disabled, setDisabled] =  useState(false);
    const [selectedImage, setSelectedImage] =  useState(null);
    const [name, setName] = useState("");
    const [status, setStatus] = useState(0);
    const [description, setDescription] = useState("");
    const [category_id, setCategoryId] = useState(0);
    const { token,  setToken } = useToken();

    const toggleDrawer = () => {
        setOpen(!open);
    };
    const navigate = useNavigate();

// This function will be triggered when the file field change
    const imageChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setDisabled(true);
            setSelectedImage(e.target.files[0]);
        }
    };

    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append("name", name);
        formData.append("description", description);
        formData.append("category_id", category_id);
        formData.append("status", status);
        formData.append("photo", selectedImage);

        axios.post('https://admin.sogreen.tn/api/libplantes_crud/save',   formData   ,config).then(function(response){

           navigate('/biblioplantes');
        });

    }



// This function will be triggered when the "Remove This Image" button is clicked
    const removeSelectedImage = () => {
        setSelectedImage();
        setDisabled(false);
    };
    if(!token) {
        return <Login setToken={setToken} />
    }
    return (

        <ThemeProvider theme={defaultTheme}>
            <CssBaseline />
            <AppBar position="absolute" open={open}>
                <Toolbar  sx={{ pr: '24px', }} >
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleDrawer}
                        sx={{
                            marginRight: '36px',
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{ flexGrow: 1 }}
                    >
                        Dashboard
                        </Typography>
                        <LogoutButton />

                </Toolbar>
                <Box sx={{ display: 'flex' }}>
                    <Drawer variant="permanent" open={open}>
                        <Toolbar
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                                px: [1],
                            }}
                        >
                            <IconButton onClick={toggleDrawer}>
                                <ChevronLeftIcon />
                            </IconButton>
                        </Toolbar>
                        <Divider />
                        <List component="nav">
                            <ListItems/>
                        </List>
                    </Drawer>
                    <Box
                        component="main"
                        sx={{
                            backgroundColor: (theme) =>
                                theme.palette.mode === 'light'
                                    ? theme.palette.grey[100]
                                    : theme.palette.grey[900],
                            flexGrow: 1,
                            height: '100vh',
                            overflow: 'auto',
                        }}
                    >
                        <Toolbar />
                    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                        <Grid container spacing={3}>
                            {/* Chart */}
                            <Grid item xs={12} md={8} lg={12}>
                                <Paper style={{  padding:'0 10px'}}
                                       sx={{
                                           p: 2,
                                           display: 'flex',
                                           flexDirection: 'column',
                                           height: 50,
                                       }}
                                >  <h2 style={{margin:4, padding:0}}>Ajouter une Plante</h2>

                                </Paper>
                            </Grid>
                            {/* Recent Orders */}
                            <Grid item xs={12}>
                                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                    <form onSubmit={handleSubmit}>
                                        <table cellSpacing="10"  style={{width: '100%'}}>
                                            <tbody>
                                            <tr>
                                                <th>
                                                    <label>Nom: </label>
                                                </th>
                                                <td>
                                                    <input type="text" name="name"
                                                           onChange={(e) => setName(e.target.value)}  />
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    <label>Description: </label>
                                                </th>
                                                <td>
                                                    <textarea style={{width: '100%'}} rows='4' name="description"
                                                              onChange={(e) => setDescription(e.target.value)}  />
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    <label>Category: </label>
                                                </th>
                                                <td>
                                                    <select name="category_id" id='category_id'

                                                            onChange={(e) => setCategoryId(e.target.value)}   defaultValue="0">
                                                        {planteCatNames.map((name, index) => (
                                                            <option key={index}
                                                                id={index}
                                                                value={index}
                                                            >{name}</option>
                                                        ))}
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    <label>Etat: </label>
                                                </th>
                                                <td>
                                                    <select name="status" id='status' onChange={(e) => setStatus(e.target.value)} defaultValue="0">
                                                        <option key='1' value='0'>Inactive</option>
                                                        <option key='2' value='1'>Active</option>
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    <label>Photo: </label>
                                                </th>
                                                <td   align ="right">

                                                    <div style={styles.container}>
                                                        <input className="inputimgplante"
                                                               accept="image/*"
                                                               type="file"
                                                               name="photo"

                                                               onChange={imageChange}

                                                               disabled = {disabled}
                                                        />
                                                        {selectedImage && (
                                                            <div style={{
                                                                marginTop: 0 }} >
                                                                <div style={styles.preview} >
                                                                    <IconButton  onClick={removeSelectedImage} style={styles.delete}>
                                                                        <CloseIcon />
                                                                    </IconButton >
                                                                    <img
                                                                        src={URL.createObjectURL(selectedImage)}
                                                                        style={styles.image}
                                                                        alt="Thumb"
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="2" align ="right">
                                                    <Button  variant="contained" className='btnaction' type='submit'>Sauvegarder</Button>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </form>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Container>
                        <Copyright sx={{ pt: 4 }} />
                    </Box>
                </Box>
            </AppBar>
        </ThemeProvider>
    );
}



// Just some styles
const styles = {
    container: {
        flexDirection: "column",
        justifyContent: "left",
        alignItems: "left",
        maxWidth: 'auto' ,
        width: 'auto',
        paddingTop: 5,
        marginTop: 10,
        marginLeft: 20,
        background: '#f1f1f1',
        position: 'relative'

    },
    preview: {
        position: 'relative',
        maxWidth: 100,
        minHeight: 100,
        padding: 0,
        margin: 0,
        marginTop: 20,
        display: "flex",
        flexDirection: "column",
        borderRadius: 10,
    },
    image: {
        width: 100,
        height: 100,
        position: 'absolute',
        top: 0, right: 0
    },
    delete: {
        zIndex: 10,
        position: 'absolute',
        top: 0, right: 0,
        width: 25, height: 25,
        cursor: "pointer",
        padding: 0,
        background: "#EEEEEE",
        color: "red",
        border: "none",
    },
};
