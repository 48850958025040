import * as React from 'react';
import {useNavigate} from "react-router-dom";
import {PersonAddAlt} from "@mui/icons-material";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { Button, } from "@mui/material";

export default function Admins() {

    const navigate = useNavigate();

    const GoToHandler = (url) => {
        navigate(url);
    }
    const handleGoTo = (path) => (e) => {
        GoToHandler(path);
    }


    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
                {/* Chart */}
                <Grid item xs={12} md={8} lg={9}>
                    <Paper style={{  padding:'0 10px'}}
                           sx={{
                               p: 2,
                               display: 'flex',
                               flexDirection: 'column',
                               height: 50,
                           }}
                    >  <h2 style={{margin:4, padding:0}}>Liste Admins</h2>

                    </Paper>
                </Grid>
                {/* Recent Deposits */}
                <Grid item xs={12} md={4} lg={3}>
                    <Paper style={{  padding:'0 10px'}}
                           sx={{
                               p: 2,
                               display: 'flex',
                               flexDirection: 'column',
                               height: 50,
                           }}
                    >
                        <Button variant="contained"
                                size="medium"
                                aria-label="Ajouter un Admin"
                                aria-controls="primary-account-menu"
                                color="success"
                                 onClick={() => handleGoTo('/admin/create')}
                        >
                            <PersonAddAlt/>Ajouter un Admin
                        </Button>
                    </Paper>
                </Grid>
                {/* Recent Orders */}

            </Grid>
        </Container>
    );
}
