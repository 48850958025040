import * as React from 'react';
import {  createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ListItems from '../theme/components/listItems';
import Copyright from "../theme/components/Copyright";
import {Drawer} from "../theme/components/StyledComponent";
import AppBar from "@mui/material/AppBar";
import Paper from "@mui/material/Paper";
import styled from "@emotion/styled";
import {useEffect, useState} from "react";
import axios from "axios";
import LogoutButton from "../components/Logout";
import Login from "./Login";
import useToken from "../components/useToken";


const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: 60,
    lineHeight: '60px',
}));
// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme( );

export default function Dashboard() {
    const [open, setOpen] = React.useState(true);

    const { token,  setToken } = useToken();
    const toggleDrawer = () => {
        setOpen(!open);
    };
    const [users, setUsers] = useState([]);
    const [lib_plantes, setLibPlantes] = useState([]);
    const [shop_plantes, setShopPlantes] = useState([]);

    useEffect(() => {
        getUsers();
        getLibPlantes();
        getShopPlantes();
    }, []);

    function getUsers() {
        axios.get('https://admin.sogreen.tn/api/users_crud/').then(function (response) {
            //  console.log(response.data);
            setUsers(response.data);
        });
    }
    function getLibPlantes() {
        axios.get('https://admin.sogreen.tn/api/libplantes_crud/').then(function (response) {
            //console.log(response.data);
            setLibPlantes(response.data);
        });
    }

    function getShopPlantes() {
        axios.get('https://admin.sogreen.tn/api/shopplantes_crud/').then(function (response) {
            //  console.log(response.data);
            setShopPlantes(response.data);
        });
    }

    if(!token) {
        return <Login setToken={setToken} />
    }
    return (
        <ThemeProvider theme={defaultTheme}>
            <CssBaseline />
                <AppBar position="absolute" open={open}>
                <Toolbar  sx={{ pr: '24px', }} >
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={toggleDrawer}
                            sx={{
                                marginRight: '36px',
                                ...(open && { display: 'none' }),
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography
                            component="h1"
                            variant="h6"
                            color="inherit"
                            noWrap
                            sx={{ flexGrow: 1 }}
                        >
                            Dashboard
                        </Typography>
                        <LogoutButton />
                    </Toolbar>
               <Box sx={{ display: 'flex' }}>
                <Drawer variant="permanent" open={open}>
                        <Toolbar
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                                px: [1],
                            }}
                        >
                            <IconButton onClick={toggleDrawer}>
                                <ChevronLeftIcon />
                            </IconButton>
                        </Toolbar>
                        <Divider />
                        <List component="nav">
                            <ListItems/>
                        </List>
                    </Drawer>
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Toolbar />
                    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                        <Grid container spacing={3}>

                            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>

                                <Grid item xs={2} sm={4} md={4} key={1}>
                                    <Item style={{fontSize: 18, color: '#000000'  }}><b style={{fontSize: 24, color: '#1976d2'  }}>{ users.length }</b> Utilisateurs</Item>
                                </Grid>
                                <Grid item xs={2} sm={4} md={4} key={2}>
                                      <Item style={{fontSize: 18, color: '#000000'  }}><b style={{fontSize: 24, color: '#1976d2'  }}>{ lib_plantes.length }</b> Plantes dans la Biblio</Item>
                                </Grid>
                                <Grid item xs={2} sm={4} md={4} key={3}>
                                    <Item style={{fontSize: 18, color: '#000000'  }}><b style={{fontSize: 24, color: '#1976d2'  }}>{ shop_plantes.length }</b> Plantes dans la Marketplace</Item>

                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                    <Copyright sx={{ pt: 4 }} />
                </Box>
            </Box>
        </AppBar>
        </ThemeProvider>
    );
}
