import {useEffect, useState} from "react";
import axios from "axios"
import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import {useNavigate} from "react-router-dom";


import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import AppBar from "@mui/material/AppBar";
import {PersonAddAlt} from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import ListItems from '../../theme/components/listItems';

import Copyright from "../../theme/components/Copyright";
import {Drawer} from "../../theme/components/StyledComponent";
import {planteCatNames, StatusEnum} from "../../theme/components/list_enum";
import useToken from "../../components/useToken";
import Login from "../../layouts/Login";
import LogoutButton from "../../components/Logout";

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function PlantStore() {
    const [open, setOpen] = React.useState(true);
    const toggleDrawer = () => {
        setOpen(!open);
    };
    const navigate = useNavigate();
    const [shop_plantes, setShopPlantes] = useState([]);
    const { token,  setToken } = useToken();
    useEffect(() => {
        getShopPlantes();
    }, []);

    function getShopPlantes() {
        axios.get('https://admin.sogreen.tn/api/shopplantes_crud/').then(function (response) {
          //  console.log(response.data);
            setShopPlantes(response.data);
        });
    }

    const goToEditHandler = (id) => {
        navigate(`/marketplace/${id}/edit`);
    }
    const handleEditOpen = (user) => (e) => {
        goToEditHandler(user.id);
    };

    const deleteShopPlante = (id) => {
        let answer = window.confirm("vous voulez vraiment supprimer cette plante ?");
        if(answer) {
            axios.delete(`https://admin.sogreen.tn/api/shopplantes_crud/${id}/delete`).then(function (response) {
                //  console.log(response.data);
                getShopPlantes();
            });
        }
    }

    const GoToHandler = (url) => {
        navigate(url);
    }
    const handleGoTo = (path) => (e) => {
        GoToHandler(path);
    };
    const handleDeleteShopPlante = (user) => (e) => {
        deleteShopPlante(user.id);
    };

    const truncate = (input) =>
        input?.length > 100 ? `${input.substring(0, 80)}...` : input;


    const loadImg = (url) =>{
        let  imageC='';
        if(url) {
            if(url.toLowerCase().includes("http://")  || url.toLowerCase().includes("https://")  ){
                imageC=<img src={url} width='150' alt=''/>
            }else{
                imageC=<img src={`https://demo.sogreen.tn${url}`} alt='' width='150'/>

            }
        }
        return imageC;
    };


    if(!token) {
        return <Login setToken={setToken} />
    }
    return (
        <ThemeProvider theme={defaultTheme}>
            <CssBaseline />
            <AppBar position="absolute" open={open}>
                <Toolbar  sx={{ pr: '24px', }} >
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleDrawer}
                        sx={{
                            marginRight: '36px',
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{ flexGrow: 1 }}
                    >
                        Dashboard
                        </Typography>
                        <LogoutButton />

                </Toolbar>
                <Box sx={{ display: 'flex' }}>
                    <Drawer variant="permanent" open={open}>
                        <Toolbar
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                                px: [1],
                            }}
                        >
                            <IconButton onClick={toggleDrawer}>
                                <ChevronLeftIcon />
                            </IconButton>
                        </Toolbar>
                        <Divider />
                        <List component="nav">
                            <ListItems/>
                        </List>
                    </Drawer>
                    <Box
                        component="main"
                        sx={{
                            backgroundColor: (theme) =>
                                theme.palette.mode === 'light'
                                    ? theme.palette.grey[100]
                                    : theme.palette.grey[900],
                            flexGrow: 1,
                            height: '100vh',
                            overflow: 'auto',
                        }}
                    >
                        <Toolbar />
                    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                        <Grid container spacing={3}>
                            {/* Chart */}
                            <Grid item xs={12} md={8} lg={9}>
                                <Paper style={{  padding:'0 10px'}}
                                    sx={{
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: 50,
                                    }}
                                >  <h2 style={{margin:4, padding:0}}>Liste des Plantes: Marketplace ({shop_plantes.length})</h2>

                                </Paper>
                            </Grid>
                            {/* Recent Deposits */}
                            <Grid item xs={12} md={4} lg={3}>
                                <Paper style={{  padding:'0 10px'}}
                                    sx={{
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: 50,
                                    }}
                                >
                                    <Button variant="contained"
                                        size="medium"
                                        aria-label="Ajouter une Plante"
                                        aria-controls="primary-account-menu"
                                        color="success"
                                        onClick={handleGoTo('/marketplace/create')}
                                    >
                                        <PersonAddAlt/>Ajouter
                                    </Button>
                                </Paper>
                            </Grid>
                            {/* Recent Orders */}
                            <Grid item xs={12}>
                                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                    <Container fixed>
                                        <TableContainer component={Paper}>
                                            <Table sx={{minWidth: 700}} aria-label="Liste des Plantes">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align="center">ID</TableCell>
                                                        <TableCell align="left">Image</TableCell>
                                                        <TableCell align="left">Categorie</TableCell>
                                                        <TableCell align="left">Nom</TableCell>
                                                        <TableCell align="left">Description</TableCell>
                                                        <TableCell align="left">Prix</TableCell>
                                                        <TableCell align="left">Etat</TableCell>
                                                        <TableCell align="left">Mis à jour à</TableCell>
                                                        <TableCell align="center">Action</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody hidden={!shop_plantes}>
                                                    {shop_plantes.map((row) => (
                                                        <TableRow key={row.id}>
                                                            <TableCell align="center">
                                                                {row.id}
                                                            </TableCell>
                                                            <TableCell align="left">{loadImg(row.thumb)}</TableCell>
                                                            <TableCell align="left">{planteCatNames[row.category_id]}</TableCell>
                                                            <TableCell align="left">{row.name}</TableCell>
                                                            <TableCell align="left">{truncate(row.description)}</TableCell>
                                                            <TableCell align="left">{row.prix}</TableCell>
                                                            <TableCell align="left">{StatusEnum[row.status]}</TableCell>
                                                            <TableCell align="left">{row.updated_at}</TableCell>
                                                            <TableCell align="center" width={150}>
                                                                <Button onClick={handleEditOpen(row)}
                                                                        style={{marginRight: "10px"}}>Edit
                                                                </Button>
                                                                <IconButton aria-label="delete" onClick={handleDeleteShopPlante(row)}>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Container>
                                </Paper>
                            </Grid>
                        </Grid>

                    </Container>
                    <Copyright sx={{ pt: 4 }} />
                </Box>
            </Box>
        </AppBar>
</ThemeProvider>
    );
}
