import React from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './index.css';
import './App.css';
import ReactDOM from 'react-dom/client';
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {BrowserRouter, Route, Routes} from "react-router-dom";

import Dashboard from "./layouts/Dashboard";
import Users from './pages/User/users';
import CreateUser from './pages/User/create-user';
import EditUser from './pages/User/edit-user';
import PlantLibrary from './pages/Library/plant-library';
import PlantStore from "./pages/Store/plant-store";
import Categories from "./pages/Category/categories";
import CreateCategory from "./pages/Category/create-category";
import EditCategory from "./pages/Category/edit-category";
import CreatePlantStore from "./pages/Store/create-plant-store";
import CreatePlantLibrary from "./pages/Library/create-plant-library";
import EditPlantStore from "./pages/Store/edit-plant-store";
import EditPlantLibrary from "./pages/Library/edit-plant-library";
import Admins from "./pages/Admin/admins";
import CreateAdmin from "./pages/Admin/create-admin";
import EditAdmin from "./pages/Admin/edit-admin";
import {DevSupport} from "@react-buddy/ide-toolbox";
import {ComponentPreviews, useInitial} from "./dev";
import Ideas from "./pages/Ideas/ideas";
import EditIdea from "./pages/Ideas/edit-idea";
import CreateIdea from "./pages/Ideas/create-idea";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <div className="app-wrapper">
        <React.StrictMode>
            <BrowserRouter>
                <Routes>
                    <Route index element={<DevSupport ComponentPreviews={ComponentPreviews}
                                                      useInitialHook={useInitial}
                    >
                        <App/>
                    </DevSupport>}/>
                    <Route exact path="dashboard" element={<Dashboard/>}/>
                    <Route exact path="listusers" element={<Users/>}/>
                    <Route exact path="user/create" element={<CreateUser/>}/>
                    <Route exact path="user/:id/edit" element={<EditUser/>}/>
                    <Route exact path="listadmins" element={<Admins/>}/>
                    <Route exact path="admin/create" element={<CreateAdmin/>}/>
                    <Route exact path="admin/:id/edit" element={<EditAdmin/>}/>
                    <Route exact path="biblioplantes" element={<PlantLibrary/>}/>
                    <Route exact path="library/create" element={<CreatePlantLibrary/>}/>
                    <Route exact path="library/:id/edit" element={<EditPlantLibrary/>}/>
                    <Route exact path="marketplantes" element={<PlantStore/>}/>
                    <Route exact path="marketplace/create" element={<CreatePlantStore/>}/>
                    <Route exact path="marketplace/:id/edit" element={<EditPlantStore/>}/>
                    <Route exact path="categoryplantes" element={<Categories/>}/>
                    <Route exact path="category/create" element={<CreateCategory/>}/>
                    <Route exact path="category/:id/edit" element={<EditCategory/>}/>
                    <Route exact path="ideas_and_conseil" element={<Ideas/>}/>
                    <Route exact path="ideas_and_conseil/create" element={<CreateIdea/>}/>
                    <Route exact path="ideas_and_conseil/:id/edit" element={<EditIdea/>}/>
                </Routes>
            </BrowserRouter>
        </React.StrictMode>
    </div>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
