import React  from 'react';
import './Login.css';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import PropTypes from 'prop-types';
import axios from "axios";
import useAdmin from "../components/useAdmin";
import logo from "../assets/img/logo.svg"

export default function Login({ setToken }) {
    const { admin,  setAdmin } = useAdmin();

    const config = {
        headers: {
            'content-type': 'application/json'
        }
    }
    function loginUser(credentials) {
        return axios.post('https://admin.sogreen.tn/api/admin/login',credentials,config).then(function (response) {

            if(response.data.status){
                setAdmin({"admin":response.data.admin});
                return {"token":response.data.token}
            }
        });
    }

    const handleSubmit = async  (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const token = await loginUser({
            username: data.get("email"),
            password: data.get("password"),
        });
        if(token){
            setToken(token);
        }
    };
    return(
            <Container component="main" maxWidth="xs">
                <Box
                    sx={{
                        boxShadow: 3,
                        borderRadius: 2,
                        px: 4,
                        py: 6,
                        marginTop: 8,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >   <img src={logo} alt='logo admin sogreen' width='150'  style={{ margin: '15px auto '}}/>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            style={{backgroundColor: '#096946'}}
                        >
                            Sign In
                        </Button>

                    </Box>
                </Box>
            </Container>
    )
}
Login.propTypes = {
    setToken: PropTypes.func.isRequired
};
