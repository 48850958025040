import * as React from 'react';
import { useState} from "react";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

export default function CreateAdmin() {

    const navigate = useNavigate();
    const [inputs, setInputs] = useState([]);

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}));
    }
    const handleSubmit = (event) => {
        event.preventDefault();

        axios.post('https://admin.sogreen.tn/api/users_crud/save', inputs).then(function(response){
           // console.log(response.data);
            navigate('/listusers');
        });

    }
    return (

         <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                        <Grid container spacing={3}>

                            <Grid item xs={12}>
                                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                    <form onSubmit={handleSubmit}>
                                        <table cellSpacing="10">
                                            <tbody>
                                            <tr>
                                                <th>
                                                    <label>Nom: </label>
                                                </th>
                                                <td>
                                                    <input type="text" name="first_name" onChange={handleChange} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    <label>Prenom: </label>
                                                </th>
                                                <td>
                                                    <input type="text" name="last_name" onChange={handleChange} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    <label>Email: </label>
                                                </th>
                                                <td>
                                                    <input type="text" name="email" onChange={handleChange} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    <label>Etat: </label>
                                                </th>
                                                <td>
                                                    <select name="status" id='status' onChange={handleChange} defaultValue="0">
                                                        <option value='0'>Inactive</option>
                                                        <option value='1'>Active</option>
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="2" align ="right">
                                                    <button>Sauvegarder</button>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </form>
                                </Paper>
                            </Grid>
                        </Grid>
        </Container>

    );
}


