import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

import {useNavigate} from "react-router-dom";
import {  PersonSearch, PhotoLibrary} from "@mui/icons-material";

export default function ListItems() {

    const navigate = useNavigate();



    const GoToHandler = (url) => {
        navigate(url);
    }
    const handleGoTo = (path) => (e) => {
        GoToHandler(path);
    };
    return (

        <>
            <ListItemButton onClick={handleGoTo('/dashboard')}>
                <ListItemIcon>
                    <DashboardIcon/>
                </ListItemIcon>
                <ListItemText primary="Dashboard"/>
            </ListItemButton>
            <ListItemButton onClick={handleGoTo('/listusers')}>
                <ListItemIcon>
                    <PersonSearch/>
                </ListItemIcon>
                <ListItemText primary="Utilisateurs"/>
            </ListItemButton>

            <ListItemButton onClick={handleGoTo('/biblioplantes')}>
                <ListItemIcon>
                    <PhotoLibrary/>
                </ListItemIcon>
                <ListItemText primary="Biblio des plantes"/>
            </ListItemButton>
            <ListItemButton onClick={handleGoTo('/marketplantes')}>
                <ListItemIcon>
                    <ShoppingCartIcon/>
                </ListItemIcon>
                <ListItemText primary="Marketplace"/>
            </ListItemButton>
            <ListItemButton onClick={handleGoTo('/ideas_and_conseil')}>
                <ListItemIcon>
                    <ShoppingCartIcon/>
                </ListItemIcon>
                <ListItemText primary="Idées & conseils"/>
            </ListItemButton>
            <ListItemButton onClick={handleGoTo('/categoryplantes')}>
                <ListItemIcon>
                    <ShoppingCartIcon/>
                </ListItemIcon>
                <ListItemText primary="Liste categories"/>
            </ListItemButton>
        </>
    );

}

