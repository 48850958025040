import {useEffect, useState} from "react";
import axios from "axios"
import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import {useNavigate} from "react-router-dom";

import DeleteIcon from '@mui/icons-material/Delete';

import * as React from 'react';
import {  createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import AppBar from "@mui/material/AppBar";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ListItems from '../../theme/components/listItems';

import Copyright from "../../theme/components/Copyright";
import {Drawer} from "../../theme/components/StyledComponent";
import {StatusEnum} from "../../theme/components/list_enum";
import useToken from "../../components/useToken";
import Login from "../../layouts/Login";
import LogoutButton from "../../components/Logout";

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function Users() {
    const [open, setOpen] = React.useState(true);
    const toggleDrawer = () => {
        setOpen(!open);
    };
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const { token,  setToken } = useToken();
    useEffect(() => {
        getUsers();
    }, []);

    function getUsers() {
        axios.get('https://admin.sogreen.tn/api/users_crud/').then(function (response) {
           // console.log(response.data);

            setUsers(response.data.sort((a, b) => (a.qty > b.qty) ? 1 : -1));
        });
    }


    const goToEditHandler = (id) => {
        navigate(`/user/${id}/edit`);
    }
    const handleEditOpen = (id) => (e) => {
        goToEditHandler(id);
    };
    const deleteUser = (id) => {

            axios.delete(`https://admin.sogreen.tn/api/users_crud/${id}/delete`).then(function (response) {
                // console.log(response.data);
                getUsers();
            });
    }


    const handleDeleteUser = (user) => (e) => {
        let answer = window.confirm("vous voulez vraiment supprimer cette utilisateur ?");
        if(answer) {
             deleteUser(user.id);
        }


    };
    if(!token) {
        return <Login setToken={setToken} />
    }
    return (
        <ThemeProvider theme={defaultTheme}>
            <CssBaseline />
            <AppBar position="absolute" open={open}>
                <Toolbar sx={{ pr: '24px', }} >
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleDrawer}
                        sx={{
                            marginRight: '36px',
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{ flexGrow: 1 }}
                    >
                        Dashboard
                        </Typography>
                        <LogoutButton />
                </Toolbar>
                <Box sx={{ display: 'flex' }}>
                    <Drawer variant="permanent" open={open}>
                        <Toolbar
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                                px: [1],
                            }}
                        >
                            <IconButton onClick={toggleDrawer}>
                           <ChevronLeftIcon />
                            </IconButton>
                        </Toolbar>
                        <Divider />
                        <List component="nav">
                            <ListItems/>
                        </List>
                    </Drawer>
                    <Box
                        component="main"
                        sx={{
                            backgroundColor: (theme) =>
                                theme.palette.mode === 'light'
                                    ? theme.palette.grey[100]
                                    : theme.palette.grey[900],
                            flexGrow: 1,
                            height: '100vh',
                            overflow: 'auto',
                        }}
                    >
                        <Toolbar />
                        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                            <Grid container spacing={3}>
                                {/* Chart */}
                                <Grid item xs={12} md={8} lg={12}>
                                    <Paper style={{  padding:'0 10px'}}
                                           sx={{
                                               p: 2,
                                               display: 'flex',
                                               flexDirection: 'column',
                                               height: 50,
                                           }}
                                    >  <h2 style={{margin:4, padding:0}}>Liste Utilisateurs ({users.length})</h2>

                                    </Paper>
                                </Grid>
                                {/* Recent Deposits */}

                                {/* Recent Orders */}
                                <Grid item xs={12}>
                                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                        <Container fixed>
                                            <TableContainer component={Paper}>
                                                <Table sx={{minWidth: 700}} aria-label="List Users">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>ID</TableCell>
                                                            <TableCell align="left">Email</TableCell>
                                                            <TableCell align="left">Nom</TableCell>
                                                            <TableCell align="left">Prenom</TableCell>
                                                            <TableCell align="left">Etat</TableCell>
                                                            <TableCell align="left">Date inscription</TableCell>
                                                            <TableCell align="right">Action</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody hidden={!users}>
                                                        {users.map((row) => (
                                                            <TableRow key={row.id}>
                                                                <TableCell component="th" scope="row">
                                                                    {row.id}
                                                                </TableCell>
                                                                <TableCell align="left">{row.email}</TableCell>
                                                                <TableCell align="left">{row.first_name}</TableCell>
                                                                <TableCell align="left">{row.last_name}</TableCell>
                                                                <TableCell align="left">{StatusEnum[row.status]}</TableCell>
                                                                <TableCell align="left">{row.created_at}</TableCell>
                                                                <TableCell align="right">
                                                                    <Button onClick={handleEditOpen(row.id)} style={{marginRight: "10px"}}>Edit</Button>
                                                                    <IconButton aria-label="delete" onClick={handleDeleteUser(row)}>
                                                                        <DeleteIcon />
                                                                    </IconButton>

                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Container>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Container>
                        <Copyright sx={{ pt: 4 }} />
                    </Box>
                </Box>
            </AppBar>
        </ThemeProvider>
    );
}
